<template>
  <div>
    <headerSection></headerSection>

    <div id="main__content" class="">
      <div class="x-main-container">
        <div class="x-main-content">
          <!-- 'height':'100vh'-->
          <!-- background-size: cover;
    background-attachment: fixed;
    background-position: center; -->
          <section
            class="x-index-top-container"
            :style="{ 'background-image': `url(${require('../assets/images/Shiba-BG.jpg')})`,'background-size':'cover','background-attachment':'fixed','background-position':'center'}"
          >
            <div class="x-wrapper-right-container"></div>
            <div class="-index-inner-wrapper">
              <div class="-heading-wrapper js-replace-heading-wrapper">
                <h1 class="-title">
                  ระบบ Casino ที่ดีที่สุด มีแบรนด์ให้เลือกเล่นมากมาย
                </h1>
              </div>
              <swiper
                ref="mySwiper"
                :options="swiperOptions"
                style="padding-top:10px;padding-left:10px;padding-right:10px;"
              >
                <swiper-slide v-for="(item,index) in banners" :key="index">
                  <img
                    style="margin: auto;width: 650px;border-radius: 15px;"
                    class="img-fluid -slick-item -item-1"
                    :alt="item.name"
                    width="1200"
                    height="590"
                    :src="item.image"
                /></swiper-slide>
                <!-- <div class="swiper-pagination" slot="pagination"></div> -->
              </swiper>
              <div class="d-lg-none" style="padding-top:10px;">
                <div class="x-feed-news-header">
                  <div
                    class="-feed-news-inner-wrapper animated fadeInUp"
                    data-animatable="fadeInUp"
                    data-delat="200"
                  >
                    <div class="-icon-container">
                      <i class="fas fa-volume-up"></i>
                    </div>

                    <div class="-track">
                      <div class="-track-item -duration-normal-content">
                        .. ยินดีต้อนรับสู่ Fast828 โลกแห่งการเดิมพันที่ดีที่สุด
                        ..
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="-games-provider-wrapper">
                <div class="-games-index-page">
                  <div
                    class="x-category-provider js-game-scroll-container js-game-container"
                  >
                    <div class="-top-paragraph">
                      <div class="container">
                        <div class="row">
                          <div class="col-12 px-2 px-lg-3">
                           <img src="../assets/images/title-shiba.png" alt="Fast828 ศูนย์รวมแบรนด์ชั้นยอด อันดับ 1" style="width: 100%;max-width: 800px;height: auto;">
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="-games-list-container container">
                      <nav class="nav-menu" id="navbarProvider">
                        <ul class="nav nav-pills">
                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                          <router-link to="/play" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-11.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                              href="javascript:void(0)"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              rel="nofollow noopener"
                              data-toggle="modal"
						                  data-target="#depositModal"
                              @click="checkPromotions()"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-01.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                               href="javascript:void(0)"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              data-toggle="modal"
						                  data-target="#withdrawModal"
                              @click="moveCreditToWallet()"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-02.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li> -->
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/promotion" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-05.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/history" class="nav-link js-account-approve-aware">
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-03.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/partner" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="partner">
                                  <img
                                    src="../assets/images/menu/Menu-04.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-04.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container " v-if="!disablecontinue"
                          >
                            <router-link to="/continue" class="nav-link js-account-approve-aware">
                              <picture>
                                  <template v-if="continueCheck">
                                  <img
                                    src="../assets/images/menu/Menu-06.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-06.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/cashback" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="cashback">
                                  <img
                                    src="../assets/images/menu/Menu-07.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-07.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/spin" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="spin">
                                  <img
                                    src="../assets/images/menu/Menu-08.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-08.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>

                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/roulet" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="roulet">
                                  <img
                                    src="../assets/images/menu/Menu-09.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-09.png"
                                    alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/gashapon" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="gashapon">
                                  <img
                                    src="../assets/images/menu/Menu-15.png"
                                    alt="กาชาปอง Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-15.png"
                                    alt="กาชาปอง Shiba888 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/huay" class="nav-link js-account-approve-aware">
                              <picture>
                                <template v-if="huay">
                                  <img
                                    src="../assets/images/menu/Menu-12.png"
                                    alt="แทงหวย Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="animate__animated animate__pulse animate__infinite img-fluid -img-provider lazyload"
                                  />
                                </template>
                                <template v-else>
                                  <img
                                    src="../assets/images/menu/Menu-12.png"
                                    alt="แทงหวย Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <router-link to="/rewards" class="nav-link js-account-approve-aware">
                              <picture>
                                <template>
                                  <img
                                    src="../assets/images/menu/Menu-10.png"
                                    alt="แลกของรางวัล Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                    class="img-fluid -img-provider lazyload"
                                  />
                                </template>
                              </picture>
                            </router-link>
                          </li>
                          <!-- <li
                            class="nav-item -random-container -game-slot-macro-container "
                          >
                            <a
                              href="/_ajax_/random?randomCategory=skill-game"
                              class="nav-link js-account-approve-aware"
                              target="_blank"
                              rel="nofollow noopener"
                            >
                              <picture>
                                <img
                                  src="../assets/images/menu/Menu-10.png"
                                  alt="สุ่มเล่นสกิลเกมบนเว็บ Fast828 คาสิโน บาคาร่าสดออนไลน์"
                                  class="img-fluid -img-provider lazyload"
                                />
                              </picture>
                            </a>
                          </li> -->
                          <!-- <li class="nav-item -random-container -game-slot-macro-container">
                            <div
                              class="x-game-list-item-macro js-game-list-toggle -big "
                              data-status=""
                            >
                              <div class="-inner-wrapper">
                                <picture>
                                  <img
                                    class="img-fluid lazyload"
                                    alt="E.t. Races | wt-evo-play สกิลเกมในเว็บคาสิโนออนไลน์ รับเครดิตฟรีที่ Fast828"
                                    src="../assets/images/menu/Menu-03.png"
                                  />
                                </picture>

                               <div class="-overlay">
                                  <div class="-overlay-inner">
                                    <div class="-wrapper-container">
                                      <a
                                        href="/account/lobby/play?channelCode=wt-evo-play&amp;metadata%5Bgame_id%5D=1012"
                                        class="-btn -btn-play js-account-approve-aware"
                                        target="_blank"
                                        rel="nofollow noopener"
                                      >
                                        <i class="fas fa-play"></i>
                                        <span class="-text-btn">เข้าเล่น</span>
                                      </a>
                                      <a
                                        href="/lobby/test?channelCode=wt-evo-play&amp;metadata%5Bgame_id%5D=1012"
                                        class="-btn -btn-demo"
                                        target="_blank"
                                        rel="noopener"
                                      >
                                        ทดลองเล่น
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li> -->
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="-live-feed-wrapper-container js-replace-live-feed-wrapper"
              ></div>
            </div>
          </section>

          <footerSection></footerSection>
        </div>
      </div>

      <div class="x-right-sidebar-container"></div>

      <div
        class="x-modal modal  "
        id="ads-no-show-again"
        tabindex="-1"
        role="dialog"
        aria-hidden="true"
        data-loading-container=".js-modal-content"
        data-ajax-modal-always-reload="true"
        data-modal-one-time="Announcement Banner 1"
        data-check-target="#ads-no-show-again"
      >
        <div class="modal-dialog -modal-size " role="document">
          <div class="modal-content -modal-content">
            <button
              type="button"
              class="close f-1 "
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times"></i>
            </button>
            <div class="modal-header -modal-header">
              <h3 class="x-title-modal d-inline-block m-auto">
                <span>📢 ประกาศสำคัญ</span>
              </h3>
            </div>
            <div class="modal-body -modal-body">
              <div class="text-center" v-if="anoucement.image">
                <img
                  :src="anoucement.image"
                  alt="Announcement Fast828"
                  class="img-fluid"
                  width="350px"
                />
              </div>
              <br>
              <div v-html="nl2br(anoucement.detail)" style="font-size:15px;font-family: 'Kanit', sans-serif;"></div>
              <div class="x-checkbox-primary">
                <div class="text-primary text-center mt-4">
                  <input
                    type="checkbox" v-model="receiveAnounceMent"
                  />&nbsp;&nbsp;&nbsp;&nbsp;
                  <label style="font-size:14px;font-family: 'Kanit', sans-serif;">
                    ไม่ต้องแสดงข้อความนี้อีก
                  </label>
                </div>
              </div>

              <div class="my-3">
                <a
                  @click="submitAnoucement()"
                  data-dismiss="modal"
                  class="text-black btn btn-block -submit btn-primary text-center m-auto"
                >
                  <span>ปิดข้ามไปก่อน</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from './../router'
import firebase from 'firebase/app'
import moment from 'moment-timezone'
export default {
  name: 'Home',
  components: {
    headerSection: () => import('./Header.vue'),
    footerSection: () => import('./Footer.vue')
  },
  async mounted () {
    this.$store.dispatch('user/getBanners').catch(err => { console.error(err) })
    this.checkAnoucement()
    await this.checkDeposit()
    this.checkSpin()
    this.checkRoulet()
    this.checkCashback()
    this.checkPartner()
    const user = this.getUserId()
    if(user.key){
      firebase.database().ref(`/users/${user.key}/disablepro/continue`).once('value', (snapshot) => {
        if(snapshot.val() === false){
          this.disablecontinue = true;
        }else{
          this.checkContinue()
        }
      })
    }
    if(Number(moment().tz("Asia/Bangkok").format('D')) === 1 || Number(moment().tz("Asia/Bangkok").format('D')) === 16){
      if(Number(moment().tz("Asia/Bangkok").format('H')+''+moment().tz("Asia/Bangkok").format('mm')) <= 1520){
        this.checkHuay()
      }
    }
    //Snackbar.show({pos: 'top-center',text: 'Example notification text.',actionText:'Click',actionTextColor:'#ffd74c',backgroundColor:'#47124A'});
  },
  computed: {
    banners () {
      return this.$store.state.user.banners
    },
    swiper () {
      return this.$refs.mySwiper.$swiper
    },
    lastbonus () {
      return this.$store.state.user.lastbonus
    }
  },
  data () {
    return {
      deposit: 0,
      disablecontinue: false,
      continueCheck: false,
      partner: false,
      cashback: false,
      roulet: false,
      spin: false,
      huay: false,
      gashapon: false,
      receiveAnounceMent: false,
      anoucement: {},
      user: {},
      balance: 0,
      fa_spin: false,
      swiperOptions: {
        loop: true,
        speed: 2000,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false
        },
        breakpoints: {
          1024: {
            slidesPerView: 3,
            spaceBetween: 40
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          }
        },
        centeredSlides: true,
        spaceBetween: 30,
        grabCursor: true,
        pagination: {
          el: '.swiper-pagination'
        }
        // Some Swiper option/callback...
      }
    }
  },
  methods: {
    async checkDeposit () {
      const user = this.getUserId()
      let deposit = 0
      await firebase.firestore().collection('history').where('userkey', '==', user.key).where('type', '==', 'ฝาก').where('status', '==', 'ดำเนินการแล้ว').where('createdate', '>=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 00:00:00').where('createdate', '<=', moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + ' 23:59:59').get().then(async (snapshot) => {
        snapshot.forEach((doc) => {
          deposit += Number(doc.data().qty)
        })
      })
      this.deposit = deposit
    },
    getUserId () {
      const user = localStorage.getItem('userData')
      if (user) {
        const _user = JSON.parse(user)
        return _user
      } else {
        firebase.auth().signOut()
        localStorage.removeItem('userData')
        router.replace('/login')
      }
    },
    nl2br (str, is_xhtml) {
      if (typeof str === 'undefined' || str === null) {
        return ''
      }
      var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>'
      return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
    },
    // async moveCreditToWallet () {
    //   this.$store.commit('user/SET_LOADING', true)
    //   const user = this.getUserId()
		//   this.$store.dispatch('user/moveCreditToWallet').catch(err => { console.error(err) })
    //   this.$store.dispatch('user/getLastBonus', user).finally(res => {
    //     this.$store.commit('user/SET_LOADING', false)
    //     if (this.lastbonus.turncredit) {
    //       this.$store.commit('user/SET_ALERT_WITHDRAWALL', true)
    //     }
    //   }).catch(err => { console.error(err) })
	  // },
    async checkPromotions () {
      this.$store.commit('user/SET_LOADING', true)
      this.$store.dispatch('user/checkPromotions').finally(res => { this.$store.commit('user/SET_LOADING', false) }).catch(err => { console.error(err) })
    },
    async checkAnoucement () {
      const user = this.getUserId()
      const anoucement = (await firebase.database().ref('/anoucement/1').once('value')).val()
      if (anoucement && anoucement.status) {
        if (!anoucement.users || !anoucement.users[user.key]) {
          this.anoucement = anoucement
          $('#ads-no-show-again').modal('show')
        }
      }
    },
    checkSpin () {
      const user = this.getUserId()
      if (this.deposit >= 500) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.spin = true
          }
        })
      }
    },
    checkRoulet () {
      const user = this.getUserId()
      if (this.deposit >= 1000) {
        firebase.firestore().collection('roulet').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.roulet = true
          }
        })
      }
    },
    checkHuay(){
      if (this.deposit >= 200) {
        this.huay = true
      }
    },
    checkGashapon () {
      const user = this.getUserId()
      if (this.deposit >= 100) {
        firebase.firestore().collection('spin').doc(user.key + '-' + moment().tz('Asia/Bangkok').format('YYYY-MM-DD') + '-gashapon').get().then(async (snapshot) => {
          if (!snapshot.exists) {
            this.gashapon = true
          }
        })
      }
    },
    checkCashback () {
      const user = this.getUserId()
      firebase.firestore().collection('cashback').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.cashback = true
      })
    },
    checkPartner () {
      const user = this.getUserId()
      firebase.firestore().collection('partner').where('date', '==', moment().tz('Asia/Bangkok').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.partner = true
      })
    },
    checkContinue () {
      const user = this.getUserId()
      firebase.firestore().collection('continuous_deposit').where('date', '==', moment().tz('Asia/Bangkok').add(-1, 'days').format('YYYY-MM-DD')).where('userkey', '==', user.key).where('status', '==', false).get().then((snapshot) => {
        if (snapshot.size) this.continueCheck = true
      })
    },
    submitAnoucement () {
      if (this.receiveAnounceMent) {
        const user = this.getUserId()
        firebase.database().ref('/anoucement/1/users').update({
          [user.key]: true
        })
      }
    }
  }
}
</script>
<style scoped>
.x-modal .-modal-content .-modal-body {
     top: 10px;
}
@media (max-width: 575.98px) {
  .x-modal .-modal-content .-modal-body {
     top: 90px;
  }
}
</style>
